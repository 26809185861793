import axios from "axios";

const state = () => ({
    fullList: [],
})

const mutations = {
    setList(state, fullList) {
        state.fullList = fullList.sort((a, b) => (a.long_name > b.long_name) ? 1 : -1);
    },
}

const actions = {
    fetch({commit}) {
        axios.get('/v1/portfolio/?page_size=10000').then(
            r => {
                commit('setList', r.data.results)
            }
        ).catch(
            error => {
                console.log(`Failed to fetch portfolios! Details: ${error}`)
            }
        );
    },
}

const getters = {
    allPortfolios(state) {
        return state.fullList;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}