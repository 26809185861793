export default {
  created () {
    this.setTitle(this)
  },
  methods: {
    // Takes as input an instance of Vue or VueComponent.
    setTitle (vm) {
      let { title } = vm.$options
      //Check if title is a function or just a string.
      if (title) {
        title = typeof title === 'function'
            ? title.call(vm)
            : title;

        // Set title.
        if (title) {
          document.title = title;
        }
      }
    },
  }
}