<template>
  <div class="event-list-container">
    <div class="col-md-12 alert" role="alert" :class="notification.alertType"
         v-for="notification in notifications" :key="notification.event.id + notification.message">
      {{ notification.message }}
      <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <button type="button" class="btn btn-primary btn-row" v-on:click="showInactive = !showInactive">
      <span v-if="showInactive">Hide inactive events</span>
      <span v-else>Show inactive events</span>
    </button>
    <button type="button" class="btn btn-success pull-right" data-bs-toggle="modal" data-bs-target="#eventForm"
            v-on:click="clearEditEvent">
      Add new event
    </button>

    <div class="table-container">
      <MaintenanceEventTable
          :eventList="eventList"
      ></MaintenanceEventTable>
    </div>

    <MaintenanceEventForm v-on:maintenanceEventAdded="onEventAdded" v-on:maintenanceEventEdited="onEventEdited"
                          v-bind:event-edit="eventEdit"/>

    <div class="modal fade" id="eventDelete" tabindex="-1" role="dialog" aria-labelledby="eventDeleteLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="eventDeleteLabel">
              Confirm deletion
            </h5>
            <button id="eventDeleteClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Deleting the event is a permanent action that can't be reversed.</p>

            <button type="button" class="btn btn-danger btn-row btn-block" data-bs-toggle="modal"
                    data-bs-target="#eventDelete"
                    v-on:click="deletionConfirmed">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceEventForm from "@/components/MaintenanceEventForm";
import MaintenanceEventTable from "@/components/MaintenanceEventTable";
import axios from "axios";
import moment from "moment-timezone";
import _ from "underscore"
import { mapGetters } from 'vuex'

export default {
  name: 'MaintenanceEventList',
  props: {},
  components: { MaintenanceEventForm, MaintenanceEventTable },
  computed: {
    eventList() {
      let events = [];
      if (this.showInactive) {
        events = this.$store.getters['maintenance/allEvents'];
      } else {
        events = this.$store.getters['maintenance/currentEvents'];
      }
      return events.map((e) => {
        const c = this.cluster(e);
        const p = this.portfolio(e, c);
        return {
          cluster: e.asset_obj.cluster,
          asset: e.asset,
          asset_name: e.asset_obj.long_name,
          start: this.datetime_format(this.datetime_local(e.start_datetime, p)),
          end: this.datetime_format(this.datetime_local(e.end_datetime, p)),
          availability_factor: e.availability_factor,
          description: e.description,
          event: e
        }
      });
    },
    ...mapGetters({
      eventEdit: 'maintenance/getEditEvent',
      eventDelete: 'maintenance/getDeleteEvent'
    })
  },
  methods: {
    clearEditEvent() {
      this.$store.dispatch('maintenance/setEditEvent', null);
    },
    cluster(e) {
      // cluster for this event
      return _.find(this.$store.state.cluster.fullList, c => c.slug === e.asset_obj.cluster);
    },
    portfolio(e, c) {
      // portfolio for this event
      if (c === undefined) {
        return undefined;
      }
      return _.find(this.$store.state.portfolio.fullList, p => p.slug === c.portfolio);
    },
    datetime_local(datetime, p) {
      // use localize datetime if we have portfolio information otherwise use normal datetime
      if (p === undefined) {
        return moment(datetime);
      }
      return moment(datetime).tz(p.timezone);
    },

    datetime_format(datetime) {
      return datetime.format("yyyy-MM-DD HH:mm Z")
    },

    onEventAdded(eventObj) {
      // hide modal
      document.getElementById('eventFormClose').click();
      this.notifications.push({
        event: eventObj,
        message: `Event for asset ${eventObj.asset} from ${eventObj.start_datetime} to ${eventObj.end_datetime} with availability factor of ${eventObj.availability_factor} created.`,
        alertType: 'alert-success',
      });
      this.$store.dispatch('maintenance/setEditEvent', null);
    },
    onEventEdited(e) {
      // hide modal
      document.getElementById('eventFormClose').click();
      this.notifications.push({
        event: e,
        message: `Event for asset ${e.asset} from ${e.start_datetime} to ${e.end_datetime} saved.`,
        alertType: 'alert-primary',
      });
      this.$store.dispatch('maintenance/setEditEvent', null);
    },
    async deletionConfirmed() {
      let url = `/v1/maintenance_event/${this.eventDelete.id}/`;

      try {
        axios.delete(url);
      } catch (error) {
        console.log(error);
        return
      }

      this.$store.dispatch("maintenance/removeEvent", this.eventDelete).then(() => {
        this.notifications.push({
          event: this.eventDelete,
          message: `Event for asset ${this.eventDelete.asset} from ${this.eventDelete.start_datetime} to ${this.eventDelete.end_datetime} deleted.`,
          alertType: 'alert-danger',
        });
        this.$store.dispatch('maintenance/setDeleteEvent', null);
        document.getElementById('eventDeleteClose').click();
      });
    },
  },
  data: function () {
    return {
      notifications: [],
      showInactive: false
    }
  },
}
</script>
<style>
.event-list-container {
  width: 100%;
}
.btn-row {
  margin-right: 3px;
}
.table-container {
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin-top: 30px;
  padding-bottom: 30px;
}
</style>