<template>
  <div>
    <label for="inputPortfolio">Portfolio</label>
    <select id="inputPortfolio" class="form-select" ref="selectPortfolio" :value="selectedPortfolio.slug" @change="updatePortfolio()">
      <option v-for="portfolio in portfolioList" :key="portfolio.slug" :value="portfolio.slug">
        {{ portfolio.long_name }}
      </option>
    </select>
    <span class="help-block error text-danger" v-if="dataFetchError">{{ dataFetchError }}</span>
  </div>
</template>

<script>
import _ from "underscore";
import {mapState} from "vuex";

export default {
  name: 'SelectPortfolio',
  props: {
    selectedPortfolio: {slug: null},
  },
  emits: ['update:selectedPortfolio'],
  data: function () {
    return {
      dataFetchError: null,
    }
  },
  created() {
    this.$store.dispatch("portfolio/fetch")
  },
  computed: mapState({
    portfolioList: state => state.portfolio.fullList,
    portfolioReady: state => state.portfolio.fullList.length > 0,
  }),
  methods: {
    updatePortfolio() {
      let slug = this.$refs.selectPortfolio.value;
      const portfolio = _.find(this.portfolioList, p => p.slug === slug);

      if (this.portfolioReady && portfolio !== undefined) {
        this.$emit('update:selectedPortfolio', portfolio);
      }
    },
    setDefaultPortfolio() {
      // Check if url has a defined portfolio and pick it or default to first in list.
      if ("portfolio" in this.$route.params) {
        let slug = this.$route.params.portfolio;
        const portfolio = _.find(this.portfolioList, p => p.slug === slug);

        if (portfolio !== undefined) {
          this.$emit('update:selectedPortfolio', portfolio);
          return;
        }
      }
      const portfolio = this.portfolioList[0];
      if (portfolio !== undefined) {
        this.$emit('update:selectedPortfolio', portfolio);
      }
    }
  },
  watch: {
    portfolioReady() {
      this.setDefaultPortfolio();
    }
  }
}
</script>