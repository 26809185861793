<template>
    <div class="container-fluid">
      <ForecastPerformancePlots/>
    </div>
</template>

<script>
import ForecastPerformancePlots from "../components/ForecastPerformancePlots";

export default {
  name: 'ForecastPerformance',
  components: {
    ForecastPerformancePlots,
  }
}
</script>
