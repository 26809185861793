<template>
  <div>
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'Dexter Platform',
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 1em;
  height: 100vh;
}
</style>
