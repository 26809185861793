<template>
  <div>
    <label for="inputAsset">Asset</label>
    <select id="assetSelect" class="form-select inputAsset" ref="selectAsset" :value="selectedAsset.slug"
            @change="updateAsset()"
            :disabled="disabled">
      <template v-if="assetsReady">
        <option v-if="nullable" value="">
          ----
        </option>
        <option v-for="asset in assetList" :key="asset.slug" :value="asset.slug">
          {{ asset.slug }}{{ asset.slug === asset.long_name ? '' : ' - ' + asset.long_name }}
        </option>
      </template>
    </select>
    <span class="help-block error text-danger" v-if="dataFetchError">{{ dataFetchError }}</span>
    <span class="help-block error text-danger"
          v-if="assetsReady && noAssetsFound  && !disabled">Cluster has no assets</span>
  </div>
</template>

<script>
import axios from "axios";
import _ from "underscore";
import $ from "jquery";

export default {
  name: 'SelectAsset',
  props: {
    selectedPortfolio: {slug: null},
    selectedCluster: {slug: null},
    selectedAsset: {slug: null},
    initAssetSelect: Boolean,
    nullable: Boolean,
    disabled: Boolean
  },
  emits: ['update:selectedAsset', 'update:initAssetSelect'],
  data: function () {
    return {
      assetFullList: [],
      dataFetchError: null,
    }
  },
  computed: {
    assetList: function () {
      if (this.selectedCluster === null) {
        return this.assetFullList;
      }

      // filter asset list by current cluster
      return this.assetFullList.filter(cl => cl.cluster === this.selectedCluster.slug)
          .sort((a, b) => (a.long_name > b.long_name) ? 1 : -1);
    },

    selectedAssetInfo: function () {
      let asset = _.find(this.assetList, p => p.slug === this.selectedAsset);
      if (asset === undefined) {
        return {slug: null}
      }
      return asset;
    },

    noAssetsFound: function () {
      return (
          this.selectedPortfolio.slug !== null &&
          this.selectedCluster.slug !== null &&
          !this.dataFetchError &&
          (this.assetList.length === 0)
      )
    },
    assetsReady: function () {
      return this.assetFullList.length > 0 && this.selectedPortfolio.slug !== undefined && this.selectedCluster.slug !== undefined
    }
  },
  methods: {
    fetchAssets() {
      if (this.selectedPortfolio === null) {
        this.assetFullList = [];
        return;
      }

      let url;
      if (this.selectedCluster.slug === null) {
        url = `/v1/portfolio/${this.selectedPortfolio.slug}/asset/`;
      } else {
        url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/asset/`;
      }

      axios.get(
          url,
          {params: {page_size: 10000}}
      ).then(r => {
        this.dataFetchError = null;
        this.assetFullList = r.data.results;

        if (this.assetFullList.length === 0) {
          if (this.selectedCluster.slug === null)
          {
            this.dataFetchError = `Portfolio has no assets!`;
          } else {
            this.dataFetchError = `Cluster has no assets!`;
          }
        }
        this.setDefaultAsset();

        let that = this;
        $('#assetSelect').select2({
          theme: "bootstrap-5",
          dropdownParent: this.$parent.$el,
        }).on('select2:select', function () {
          that.updateAsset();
        });
      }).catch(error => {
        this.dataFetchError = `Failed to fetch assets! Details: ${error}`;
      });
    },
    updateAsset() {
      const slug = this.$refs.selectAsset.value;
      let asset = _.find(this.assetList, a => a.slug === slug);
      asset = asset ? asset : {slug: null};
      this.$emit('update:selectedAsset', asset);
    },
    setDefaultAsset() {
      // Check if url has a defined cluster and pick it or default to first in list.
      if ("asset" in this.$route.params) {
        let slug = this.$route.params.asset;
        const asset = _.find(this.assetList, a => a.slug === slug);
        if (asset !== undefined) {
          this.$emit('update:initAssetSelect', true);
          this.$emit('update:selectedAsset', asset);
          return;
        }
      }
      let asset = {slug: null};
      this.$emit('update:initAssetSelect', true);
      this.$emit('update:selectedAsset', asset);
    }
  },
  watch: {
    selectedPortfolio() {
      if (this.selectedCluster === null) {
        this.fetchAssets();
      }
    },
    selectedCluster() {
      if (this.selectedCluster !== null) {
        this.fetchAssets();
      }
      this.updateAsset();
    }
  }
}
</script>