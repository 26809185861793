<template>
  <div>
    <button type="button" class="btn btn-info btn-row" data-bs-toggle="modal" data-bs-target="#eventForm"
            v-on:click="onClickEdit">
      <vue-feather type="edit-3" size="16px" stroke="white"/>
    </button>
    <button type="button" class="btn btn-danger btn-row" data-bs-toggle="modal" data-bs-target="#eventDelete"
            v-on:click="onClickDelete">
      <vue-feather type="trash-2" size="16px"/>
    </button>
  </div>
</template>
<script>
  import store from "@/store";
  import VueFeather from 'vue-feather';

  export default {
    props: ["model"],
    components: { VueFeather },
    methods: {
      onClickDelete(e) {
        e.preventDefault();
        store.dispatch("maintenance/setDeleteEvent", this.model.event);
      },
      onClickEdit(e) {
        e.preventDefault();
        store.dispatch("maintenance/setEditEvent", this.model.event);
      }
    },
  }
</script>
<style scoped>
  button {
    display: inline-flex;
  }
</style>