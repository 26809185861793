import axios from "axios";
import moment from "moment-timezone";

const state = () => ({
    events: [],
    hasLoadedEvents: false,
    editEvent: null,
    deleteEvent: null,
})

const mutations = {
    addEvent(state, event) {
        state.events.push(event)
    },
    addEvents(state, events) {
        state.events = state.events.concat(events);
    },
    removeEvent(state, event_id) {
        state.events = state.events.filter(x => x.id !== event_id);
    },
    setDeleteEvent(state, event = null) {
        state.deleteEvent = event;
    },
    setEditEvent(state, event = null) {
        state.editEvent = event;
    },
    toggleHasLoadedEvents(state) {
        state.hasLoadedEvents = !state.hasLoadedEvents;
    }
}


const actions = {
    fetchEvents({commit}, url = null) {
        if (!url) {
            url = '/v1/maintenance_event/';
        }
        axios.get(url).then((res) => {
            commit('addEvents', res.data.results);
            if (!this.getters['maintenance/hasLoadedEvents']) {
                commit('toggleHasLoadedEvents');
            }
            if (res.data.next) {
                this.dispatch('maintenance/fetchEvents', res.data.next);
            }
        }).catch((res) => {
            console.warn(res)
        });
    },
    addEvent({commit}, event) {
        commit('addEvent', event)
    },
    removeEvent({commit}, event) {
        commit('removeEvent', event.id);
    },
    updateEvent({commit}, event) {
        commit('removeEvent', event.id);
        commit('addEvent', event)
    },
    setDeleteEvent({commit}, event) {
        commit('setDeleteEvent', event);
    },
    setEditEvent({commit}, event) {
        commit('setEditEvent', event);
    },
}
var today = moment();

const getters = {
    allEvents(state) {
        return state.events
    },
    currentEvents(state) {
        return state.events.filter(event => moment(event.end_datetime) >= today)
    },
    oldEvents(state) {
        return state.events.filter(event => moment(event.end_datetime) < today)
    },
    getEditEvent(state) {
        return state.editEvent;
    },
    getDeleteEvent(state) {
        return state.deleteEvent;
    },
    hasLoadedEvents(state) {
        return state.hasLoadedEvents;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}