import { createApp } from 'vue';
import App from './App.vue';
import store from "@/store";
import titleMixin from "./mixins/titleMixin";
import router from './router';

import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

import 'bootstrap';
import 'select2';

import "./assets/scss/custom.scss";

FusionCharts.options.license({
   key: process.env.VUE_APP_FUSION_CHARTS_KEY,
   creditLabel: false,
});

let app = createApp(App);
app.use(store);
app.use(router);
app.use(VueFusionCharts, FusionCharts, TimeSeries)
app.mixin(titleMixin);

app.mount('#app')
