import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue';
import ForecastPerformance from '../views/ForecastPerformance.vue';
import Maintenance from '../views/Maintenance.vue';

const routes = [
    {path: '/', name: 'Home', component: Home},
    {path: '/forecast_performance/', name: 'ForecastPerformance', component: ForecastPerformance},
    {
        path: '/forecast_performance/:forecast_type?/:portfolio?/:cluster?/:asset?',
        name: 'ForecastPerformanceParams',
        component: ForecastPerformance
    },
    {path: '/maintenance', name: 'Maintenance', component: Maintenance},
]

const router = createRouter({
    routes,
    history: createWebHistory()
});

export default router