import { createStore } from 'vuex'
import maintenance from "@/store/maintenance";
import cluster from "@/store/cluster";
import portfolio from "@/store/portfolio";

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        maintenance,
        cluster,
        portfolio,
    },
    strict: debug,
})