import axios from "axios";

const state = () => ({
    fullList: [],
})

const mutations = {
    setList(state, fullList) {
        state.fullList = fullList.sort((a, b) => (a.long_name > b.long_name) ? 1 : -1);
    },
}

const actions = {
    fetch({commit}, portfolio) {
        const url = `/v1/portfolio/${portfolio.slug}/cluster/?page_size=10000`
        axios.get(url).then(
            r => {
                commit('setList', r.data.results)
            }
        ).catch(
            error => {
                console.log(`Failed to fetch clusters! Details: ${error}`)
            }
        );
    },
}

const getters = {}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
