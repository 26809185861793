<template>
  <div>
    <label for="inputCluster">Cluster</label>
    <select id="inputCluster" class="form-select" ref="selectCluster" :value="selectedCluster.slug" @change="updateCluster()">
      <option v-for="cluster in clusterList" :key="cluster.slug" :value="cluster.slug">
        {{ cluster.long_name }}
      </option>
    </select>
    <span class="help-block error text-danger" v-if="dataFetchError">{{ dataFetchError }}</span>
  </div>
</template>

<script>
import _ from "underscore";
import {mapState} from "vuex";

export default {
  name: 'SelectCluster',
  props: {
    selectedPortfolio: {slug: null},
    selectedCluster: {slug: null},
    initClusterSelect: Boolean
  },
  emits: ['update:selectedCluster', 'update:initClusterSelect'],
  data: function () {
    return {
      dataFetchError: null,
      defaultCluster: {slug: null}
    }
  },
  computed: mapState({
    clusterList(state) {
      return state.cluster.fullList.filter(cl => cl.portfolio === this.selectedPortfolio.slug);
    },
    clustersReady(state) {
      return state.cluster.fullList.length > 0 && this.selectedPortfolio && this.selectedPortfolio.slug !== undefined
    },
  }),
  methods: {
    fetchClusters() {
      if (this.selectedPortfolio.slug !== null) {
        this.$store.dispatch("cluster/fetch", this.selectedPortfolio);
      }
    },
    updateCluster() {
      let slug = this.$refs.selectCluster.value;
      const cluster = _.find(this.clusterList, cl => cl.slug === slug);
      if (cluster !== undefined) {
        this.$emit('update:selectedCluster', cluster);
      }
    },
    setDefaultCluster() {
      // Check if url has a defined cluster and pick it or default to first in list.
      if ("cluster" in this.$route.params) {
        let slug = this.$route.params.cluster;
        const cluster = _.find(this.clusterList, cl => cl.slug === slug);
        if (cluster !== undefined) {
          this.$emit('update:initClusterSelect', true);
          this.$emit('update:selectedCluster', cluster);
          return;
        }
      }
      const cluster = this.clusterList[0];
      if (this.clustersReady && cluster !== undefined) {
        this.$emit('update:initClusterSelect', true);
        this.$emit('update:selectedCluster', cluster);
      }
    },
  },
  watch: {
    selectedPortfolio() {
      this.fetchClusters();
      this.setDefaultCluster();
    },
    clustersReady() {
      this.setDefaultCluster();
    }
  }
}
</script>
