<template>
  <div class="row" id="main-grid">
    <Transition
        @before-enter="onBeforeEnter"
        @after-leave="onAfterLeave" name="menu">
      <div class="col-2">
        <form class="w-100">
          <div class="form-row">
            <div class="form-group">
              <SelectPortfolio v-model:selectedPortfolio="selectedPortfolio"/>
            </div>
            <div class="form-group">
              <SelectCluster
                  :selectedPortfolio="selectedPortfolio"
                  v-model:selectedCluster="selectedCluster"
                  v-model:initClusterSelect="initClusterSelect"/>
            </div>
            <div class="form-group">
              <SelectAsset
                  :selectedPortfolio="selectedPortfolio"
                  :selectedCluster="selectedCluster"
                  v-model:selectedAsset="selectedAsset"
                  v-model:initAssetSelect="initAssetSelect"
                  :disabled="assetInputDisabled" nullable
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="inputStartDate">Date from</label>
              <input id="inputStartDate" type="date" class="form-control" v-model="inputStartDate">
            </div>

            <div class="form-group">
              <label for="inputEndDate">Date to</label>
              <input id="inputEndDate" type="date" class="form-control" v-model="inputEndDate">
            </div>

            <div class="form-group">
              <label for="daysAhead">Days ahead</label>
              <select id="daysAhead" class="form-select" v-model="daysAhead">
                <option v-for="daysAhead in daysAheadList" :key="daysAhead" :value="daysAhead">
                  {{ daysAhead }} days ahead
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="forecastType">Forecast type</label>
              <select id="forecastType" class="form-select" v-model="forecastType" :disabled="forecastTypeDisabled">
                <option v-for="(type, key) in forecastTypes" :key="key" :value="key">{{ type }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="forecastTime" class="label-title"
                     title="By default uses local time, but if running hindcast mode then this should be UTC">Forecast
                time</label>
              <input type="time" id="forecastTime" class="form-control" v-model="forecastTime"/>
            </div>

            <div class="form-group">
              <a class="form-control btn btn-secondary" data-bs-toggle="collapse" href="#collapseAdvanced"
                 data-bs-target="#collapseAdvanced" role="button"
                 aria-expanded="false" aria-controls="collapseAdvanced">
                Advanced Options
              </a>
            </div>
          </div>
          <div class="collapse" id="collapseAdvanced">
            <div>
              <div class="form-group">
                <div class="form-check">
                  <input type="checkbox" id="forecastMeta" class="form-check-input" v-model="forecastMeta"/>
                  <label for="forecastMeta" class="form-check-label">Show meta</label>
                </div>
              </div>

              <div class="form-group">
                <div class="form-check">
                  <input type="checkbox" id="forecastHindcast" class="form-check-input" v-model="forecastHindcast"/>
                  <label for="forecastHindcast" class="form-check-label">Hindcast mode</label>
                </div>
              </div>

              <div class="form-group">
                <div class="form-check">
                  <input type="checkbox" id="showOnlyActive" class="form-check-input" v-model="forecastOnlyActive"/>
                  <label for="showOnlyActive" class="form-check-label">Show only active assets</label>
                </div>
              </div>

              <div class="form-group">
                <label for="forecastVersion">Forecast Version</label>
                <select id="forecastVersion" class="form-select" v-model="forecastVersion">
                  <option v-for="(opt, key) in forecastVersionOptions" :key="key" :value="key">
                    {{ opt }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row">

            <div class="form-group">
              <a id="downloadCSVButton" class="form-control btn-secondary btn" :href="CSVDownloadURL">Download CSV</a>
            </div>

            <div class="form-group">
              <button id="reloadButton" class="form-control btn-primary btn" @click="onClickReload">Load Forecasts
              </button>
            </div>
          </div>
        </form>
      </div>
    </Transition>
    <div id="graphs-panel" ref="graphsPanel" class="col-8">

      <div class="col-md-12 alert alert-info" v-if="loading !== null">
        {{ loading }}
      </div>
      <div class="col-md-12 alert alert-warning" v-if="dataFetchError !== null">
        {{ dataFetchError }}
      </div>
      <div class="col-md-12 alert alert-warning" v-if="loading === null &&responseEmpty">
        No data found for selected time range
      </div>

      <div class="col-md-12 col-xl-12 col-lg-12 cost-table" v-if="!responseEmpty">
        <ForecastCostTable :imba-stats="forecastInfoRaw.imba_stats"/>
      </div>

      <div class="col-md-12 col-xl-12 col-lg-12" v-if="!responseEmpty">
        <div id="chart-container">
          <fusioncharts
              :type="type"
              :width="width"
              :height="height"
              :dataformat="dataFormat"
              :dataSource="primaryDataSource"
          >
          </fusioncharts>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 col-lg-12" v-if="!responseEmpty">
        <div id="secondary-chart-container" v-if="displaySecondaryGraph">
          <fusioncharts
              :type="type"
              :width="width"
              :height="secondaryHeight"
              :dataformat="dataFormat"
              :dataSource="secondaryDataSource"
          >
          </fusioncharts>
        </div>
      </div>

    </div>
    <div class="col-2" v-if="!responseEmpty">

      <div class="form-group col-md-12">
        <div class="form-check" v-for="(label, value) in columnNames" :key="value">
          <input type="checkbox" class="form-check-input" :value="value" v-model="secondaryAxis"/>
          <label class="form-check-label">{{ label.name }}
            {{ label.suffix.trim() != '' ? '[ ' + label.suffix.trim() + ' ]' : '' }} </label>
        </div>
      </div>
      <div class="form-group col-md-12" v-if="forecastMeta">
        <b>Meta Options</b>
        <div class="form-check" v-for="(label, value) in metaColumnNames" :key="value">
          <input type="checkbox" class="form-check-input" :value="value" v-model="secondaryAxis"/>
          <label class="form-check-label">{{ label.name }}
            {{ label.suffix.trim() != '' ? '[ ' + label.suffix + ' ]' : '' }} </label>
        </div>
      </div>
      Legend
      <ul>
        <li>Imbalance per hour; arises from the fact that energy can only be bought every hour, so we average
          predictions over each hour
        </li>
        <li>Irreducible imbalance; if we had a perfect forecast (meaning pred = actual for every pte) we
          would still pay this because we can only buy energy per hour
        </li>
        <li>Imbalance per PTE; this is just predicted load minus actual load</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ForecastCostTable from "@/components/ForecastCostTable";
import SelectPortfolio from "@/components/SelectPortfolio";
import SelectCluster from "@/components/SelectCluster";
import SelectAsset from "@/components/SelectAsset";
import moment from "moment";
import {columnNames, metaColumnNames} from "@/utils/forecasts";
import axios from "axios";
import _ from "underscore";
import FusionCharts from "fusioncharts";

export default {
  name: 'ForecastPerformancePlots',
  props: {},
  title() {
    return `${this.selectedCluster.slug ? this.selectedCluster.long_name + ' —' : ''} Dashboard - Dexter Energy Services`
  },
  components: {
    ForecastCostTable,
    SelectPortfolio,
    SelectCluster,
    SelectAsset
  },
  data() {
    let inputStartDate = moment().subtract(35, 'day').format('yyyy-MM-DD');
    inputStartDate = this.$route.query.start_date ? this.$route.query.start_date : inputStartDate;
    let inputEndDate = moment().add(3, 'day').format('yyyy-MM-DD');
    inputEndDate = this.$route.query.end_date ? this.$route.query.end_date : inputEndDate;

    let forecastType = this.$route.params.forecast_type ? this.$route.params.forecast_type : 'cluster';

    let defaultBinning = {
      'year': [],
      'month': [],
      'day': [],
      'hour': [],
      'minute': [],
      'second': [],
    };

    let primaryDataSource = {
      chart: {
        multiCanvas: false,
      },
      caption: {
        text: "Prediction vs Actuals"
      },
      series: 'Type',
      xAxis: {
        binning: {
          ...defaultBinning
        }
      }
    }

    let secondaryDataSource = {
      chart: {},
      caption: {},
      yaxis: [],
      xAxis: {
        binning: {
          ...defaultBinning
        }
      }
    };

    let requestCancelToken = null;

    // show forecast menu by default

    return {
      requestCancelToken,
      selectedPortfolio: {slug: null},
      selectedCluster: {slug: null},
      selectedAsset: {slug: null},
      daysAhead: 1,
      daysAheadList: [0, 1, 2, 3],
      forecastTypes: {
        cluster: 'Cluster forecast',
        aggregatedAsset: 'Aggregated asset forecast',
        asset: 'Individual asset forecast'
      },
      loading: null,
      columnNames,
      metaColumnNames,
      secondaryAxis: [],
      inputStartDate,
      inputEndDate,
      forecastType,
      forecastTime: null,
      forecastHindcast: false,
      forecastMeta: false,
      forecastOnlyActive: true,
      forecastVersion: '',
      forecastVersionOptions: {
        '': 'Production'
      },
      dataFetchError: null,
      forecastInfoRaw: null,
      initClusterSelect: false,
      initAssetSelect: false,
      type: 'timeseries',
      renderAt: 'chart-container',
      width: '100%',
      height: '550',
      dataFormat: 'json',
      primaryDataSource,
      secondaryDataSource,
      defaultBinning,
    }
  },
  computed: {
    allColumnNames() {
      return {...columnNames, ...metaColumnNames}
    },
    displaySecondaryGraph() {
      this.setupSecondaryDataSource();
      return this.secondaryAxis.length > 0;
    },
    secondaryHeight() {
      return this.secondaryAxis.length == 1 ? 550 : 300 * this.secondaryAxis.length;
    },
    responseEmpty() {
      return (this.forecastInfoRaw == null || Object.keys(this.forecastInfoRaw).length === 0 && this.forecastInfoRaw.constructor === Object)
    },
    inputInvalid() {
      return this.selectedPortfolio.slug === null || this.selectedCluster.slug === null || !this.datetimeEnd.isValid()
    },
    datetimeStart() {
      return moment(this.inputStartDate)
    },
    datetimeEnd() {
      return moment(this.inputEndDate)
    },
    assetInputDisabled() {
      // for the clusters where we only have clusterForecasts it does not make sense to let user select asset
      return this.selectedCluster.forecast_type === 'cluster'
    },
    forecastTypeDisabled() {
      // the user can only choose this field if this is a mixed cluster and if they have not yet selected specific
      // asset to focus on.
      return !(this.selectedCluster.forecast_type === 'mixed' && this.selectedAsset.slug === null)
    },

    urlParams() {
      let params = {
        datetime_start: this.datetimeStart.format('yyyy-MM-DD'),
        datetime_end: this.datetimeEnd.format('yyyy-MM-DD'),
        days_ahead: this.daysAhead,
        forecast_type: this.forecastType,
        forecast_label_version: this.forecastVersion,
        show_meta: this.forecastMeta,
        show_only_active: this.forecastOnlyActive,
      }
      if (this.forecastTime) {
        if (this.forecastHindcast) {
          params["reference_time_lte"] = this.forecastTime;
        } else {
          params["created_time_lte"] = this.forecastTime;
        }
      }

      return params;
    },

    urlParamsEncoded() {
      return Object.keys(this.urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(this.urlParams[k])}`).join('&');
    },

    forecastInfoURL() {
      let url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/forecast_info/`

      // detail asset forecast does not work without specifying the asset
      if (this.forecastType === 'asset' && this.selectedAsset.slug === null) {
        //let asset_slug = hashGet('asset', null)
        let asset_slug = 'slug';

        // this is a workaround where the asset is saved in URL but its object has not been loaded yet and therefore
        // the initial request to fetch the dashboard data would fail so this is a a dirty work around
        if (asset_slug !== null && asset_slug !== '') {
          url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/asset/${asset_slug}/forecast_info/`;
        } else {
          return;
        }
      }

      if (this.selectedAsset.slug !== null) {
        url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/asset/${this.selectedAsset.slug}/forecast_info/`;
      }

      return url
    },

    CSVDownloadURL() {
      return `${this.forecastInfoURL}?${this.urlParamsEncoded}&csv_output=true`
    },
    forecastLabelVersionURL() {
      return `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/forecast_label_version/`;
    }
  },
  methods: {
    calculateBinning() {
      let bin_settings = {...this.defaultBinning};
      let start = moment(this.inputStartDate);
      let end = moment(this.inputEndDate);
      let difference = moment.duration(end.diff(start));

      // Modify binning based on date range.
      if (difference.asDays() <= 144 && difference.asDays() > 72) {
        bin_settings['hour'] = [1];
        bin_settings['minute'] = [15, 30];
      } else if (difference.asDays() <= 72 && difference.asDays() > 38) {
        bin_settings['minute'] = [15, 30];
      } else if (difference.asDays() <= 38) {
        bin_settings['minute'] = [15];
      } else {
        bin_settings['hour'] = [1, 2];
        bin_settings['minute'] = [15, 30];
      }

      // Update binning settings for chart config objects.
      this.primaryDataSource.xAxis.binning = bin_settings;
      this.secondaryDataSource.xAxis.binning = bin_settings;
    },
    removeMetaSecondaryOptions() {
      const metaColumnNames = Object.keys(this.metaColumnNames);
      this.secondaryAxis = this.secondaryAxis.filter((opt) => {
        if (!metaColumnNames.includes(opt)) {
          return opt;
        }
      });
    },
    loadForecastOptions() {
      this.forecastVersion = '';
      axios.get(
          this.forecastLabelVersionURL,
      ).then(
          r => {
            let options = {
              '': 'Production'
            };
            r.data.results.forEach(o => {
              const label = o.label ? `${o.label}|` : '';
              options[o.id] = `${label}${o.version}`;
            })
            this.forecastVersionOptions = options;
          }
      ).catch(
          error => {
            console.error(error);
          }
      );
    },
    setupPrimaryDataSource() {
      let primarySchema = [
        {
          "name": "Date",
          "type": "date",
          "format": "%Y-%m-%dT%H:%M:%S%Z"
        },
        {
          "name": "Type",
          "type": "string"
        },
        {
          "name": "Predictions vs Actuals [KWh]",
          "type": "number"
        }
      ]
      let primaryData = [];
      for (let idx in this.forecastInfoRaw.pte) {
        primaryData.push([
          this.forecastInfoRaw.pte[idx],
          'Actuals [KWh]',
          this.forecastInfoRaw.columns.load[idx],
        ]);
        primaryData.push([
          this.forecastInfoRaw.pte[idx],
          'Predictions [KWh]',
          this.forecastInfoRaw.columns.pred[idx]
        ]);
      }

      const primaryFusionTable = new FusionCharts.DataStore().createDataTable(
          primaryData, primarySchema
      );
      this.primaryDataSource.data = primaryFusionTable;
    },
    setupSecondaryDataSource() {
      if (this.secondaryAxis.length > 0) {
        let secondaryYAxis = [
          ...this.secondaryAxis.map((k) => {
            return {
              plot: [
                {
                  value: `${this.allColumnNames[k].name}`,
                  connectnulldata: false
                }
              ],
              title: `${this.allColumnNames[k].name} [${this.allColumnNames[k].suffix}]`
            }
          })
        ];

        let secondarySchema = [
          {
            "name": "Date",
            "type": "date",
            "format": "%Y-%m-%dT%H:%M:%S%Z"
          },
          ...this.secondaryAxis.map((k) => {
            return {
              "name": this.allColumnNames[k].name,
              "type": "number"
            };
          })
        ];
        let secondaryData = [];
        for (let idx in this.forecastInfoRaw.pte) {
          secondaryData.push([
            this.forecastInfoRaw.pte[idx],
            ...this.secondaryAxis.map((k) => {
              return this.forecastInfoRaw.columns[k][idx]
            })
          ]);
        }

        const secondaryFusionTable = new FusionCharts.DataStore().createDataTable(
            secondaryData, secondarySchema
        );
        this.secondaryDataSource.yaxis = secondaryYAxis;
        this.secondaryDataSource.data = secondaryFusionTable;
      } else {
        this.secondaryDataSource.yaxis = [];
        this.secondaryDataSource.data = null;
      }
    },
    onClickReload(e) {
      e.preventDefault()
      this.fetchDataDebounced()
    },
    fetchData() {
      if (this.inputInvalid) {
        return
      }
      this.loading = null
      this.dataFetchError = null
      this.forecastInfoRaw = null

      this.fetchForecastInfo()
    },
    fetchForecastInfo() {
      let url = this.forecastInfoURL;

      // detail asset forecast does not work without specifying the asset
      if (this.forecastType === 'asset' && this.selectedAsset.slug === null) {
        let asset_slug = this.$route.params.asset;

        // this is a workaround where the asset is saved in URL but its object has not been loaded yet and therefore
        // the initial request to fetch the dashboard data would fail so this is a a dirty work around
        if (asset_slug !== null && asset_slug !== '') {
          url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/asset/${asset_slug}/forecast_info/`;
        } else {
          return;
        }
      }

      if (url === undefined) {
        return
      }

      // Check if we have an existing request we need to cancel.
      if (this.requestCancelToken) {
        this.requestCancelToken.cancel();
      }

      this.requestCancelToken = axios.CancelToken.source();

      this.calculateBinning();
      this.loading = 'Fetching forecast data... this can take up to 10s!';
      axios.get(
          url,
          {
            params: this.urlParams,
            cancelToken: this.requestCancelToken.token
          }
      ).then(
          r => {
            this.forecastInfoRaw = r.data;
            this.loading = null;
            this.requestCancelToken = null;
            this.setupPrimaryDataSource();
          }
      ).catch(
          error => {
            if (axios.isCancel(error)) {
              console.log('Request cancelled by user.')
            } else {
              this.loading = null;
              console.error(error);
              this.dataFetchError = `Failed to fetch forecast information!! Details: ${error.response.data?.detail}`;
            }
          }
      );
    },
    updateForecastType() {
      // User has selected specific asset to focus on: we can only show asset forecast!
      if (this.selectedAsset.slug !== null) {
        this.forecastType = 'asset';
      } else if (this.selectedCluster.forecast_type && this.selectedCluster.forecast_type == 'asset') {
        this.forecastType = 'aggregatedAsset'; // If forecast_type is asset but no asset is selected then default to aggregate.
      } else if (this.selectedCluster.forecast_type && this.selectedCluster.forecast_type == 'mixed') {
        // Cluster type is mixed, pick whatever url specifies unless it's asset, default to aggregatedAsset.
        const forecastType = this.$route.params.forecast_type ? this.$route.params.forecast_type : 'aggregatedAsset';
        this.forecastType = forecastType === 'asset' ? 'aggregatedAsset' : forecastType;
      } else {
        this.forecastType = this.selectedCluster.forecast_type;
      }
    },
    updateRoute() {
      const cluster = this.initClusterSelect ? this.selectedCluster.slug : this.$route.params.cluster;
      const asset = this.initAssetSelect ? (this.selectedAsset !== undefined ? this.selectedAsset.slug : null) : this.$route.params.asset;
      this.$router.push({
        name: 'ForecastPerformanceParams', params: {
          portfolio: this.selectedPortfolio.slug,
          cluster: cluster,
          asset: asset,
          forecast_type: this.forecastType,
        },
        query: {
          start_date: this.inputStartDate,
          end_date: this.inputEndDate,
        }
      })
    },
    onBeforeEnter() {
      this.$refs.graphsPanel.classList.replace("col-10", "col-8");
      this.$refs.menuButton.classList.replace("menu-button-closed","menu-button-open");
    },
    onAfterLeave() {
      this.$refs.graphsPanel.classList.replace("col-8", "col-10");
      this.$refs.menuButton.classList.replace("menu-button-open","menu-button-closed");
    }
  },
  watch: {
    selectedPortfolio() {
      this.updateRoute();
      this.forecastTime = this.selectedPortfolio.forecast_available_by;
    },
    selectedCluster() {
      this.updateForecastType();
      this.updateRoute();
      this.$root.setTitle(this);
      this.loadForecastOptions();
    },
    selectedAsset() {
      this.updateForecastType();
      this.updateRoute();
    },
    forecastType() {
      this.updateRoute();
    },
    inputStartDate() {
      this.updateRoute();
    },
    inputEndDate() {
      this.updateRoute();
    },
    forecastMeta() {
      this.removeMetaSecondaryOptions();
    },
    forecastOnlyActive() {
    },
  },
  created() {
    // see https://stackoverflow.com/questions/42199956/how-to-implement-debounce-in-vue2
    this.fetchDataDebounced = _.debounce(this.fetchData, 500)
  },
  mounted() {
    this.fetchDataDebounced();
  },
}
</script>

<style>
#app {
  margin-top: 0 !important;
}

#main-grid > div {
  padding-top: 1em;
  min-height: 100vh;
}


.cost-table {
  margin-bottom: 50px;
}

.input-controls {
  border-left-width: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 20px;
}

.label-title {
  cursor: help;
  text-decoration: underline;
}

.form-group {
  margin-bottom: 1em;
}

.menu-enter-active {
  animation: main 0.5s;
  transform-origin: 0% 50%;
}

.menu-leave-active {
  animation: main 0.5s reverse;
  transform-origin: 0% 50%;
}

@keyframes main {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }

}
</style>
