<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <h1>Maintenance events</h1>

        <p>
          This tool allows users to create, edit and delete maintenance events for theirs renewable assets.
          In case you have any questions or suggestions, please reach out to <a
            href="mailto:operations@dexterenergy.nl">operations@dexterenergy.nl</a>.
        </p>

        <p>
          General things to note:
        </p>
        <ul>
          <li>
            The availability factor is a value between 0 and 1.0, where 1.0 means full capacity available and 0 denotes
            no capacity available.
          </li>
          <li>
            If the maintenance takes place the entire day of 24 November 2023, the start should be 24/11/2023 at 00:00, and end should be 25/11/2023 at 00:00.
          </li>
        </ul>
      </div>
      <div class="row">
        <MaintenanceEventList/>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceEventList from "../components/MaintenanceEventList";

export default {
  name: 'Maintenance',
  components: {MaintenanceEventList},
  title() {
    return `Maintenance events - Dexter Energy Services`
  },
  created() {
    this.$store.dispatch('maintenance/fetchEvents')
  },

}
</script>


