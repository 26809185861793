<template>
  <div class="modal fade" id="eventForm" tabindex="-1" role="dialog" aria-labelledby="eventFormLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="eventFormLabel">
            <span v-if="eventEdit == null">Add new maintenance event</span>
            <span v-else>Edit event</span>
          </h5>

          <button id="eventFormClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <form class="col-12" @submit="submitForm">

              <div v-if="eventEdit === null">
                <div class="form-row col-lg-12 col-md-12 col-s">
                  <div class="form-group col-md-3 col-lg-4">
                    <SelectPortfolio v-model:selectedPortfolio="selectedPortfolio"/>
                    <span class="help-block error text-danger"
                          v-if="response_error.asset !== undefined">{{ response_error.asset }}</span>
                  </div>
                  <div class="form-group col-md-3 col-lg-4">
                    <SelectCluster :selectedPortfolio="selectedPortfolio"
                                   v-model:selectedCluster="selectedCluster"
                                   v-model:initClusterSelect="initClusterSelect"
                    />
                  </div>
                  <div class="form-group col-md-3 col-lg-4">
                    <SelectAsset :selectedPortfolio="selectedPortfolio"
                                 :selectedCluster="selectedCluster"
                                 v-model:selectedAsset="selectedAsset"
                                 v-model:initAssetSelect="initAssetSelect"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="form-group row">
                <label for="assetReadonly" class="col-2 col-form-label">Asset</label>
                <div class="col-10">
                  <input class="form-control" type="text" id="assetReadonly" :value="selectedAsset.slug" disabled>
                </div>
              </div>


              <div class="form-group row">
                <label for="dateStart" class="col-2 col-form-label">Datetime start</label>
                <div class="col-5">
                  <input class="form-control" type="date" id="dateStart" v-model="dateStart">
                  <span class="help-block error text-danger"
                        v-if="response_error.start_datetime !== undefined">{{ response_error.start_datetime[0] }}</span>
                </div>
                <div class="col-5">
                  <input class="form-control" type="time" id="timeStart" v-model="timeStart">
                </div>
              </div>

              <div class="form-group row">
                <label for="dateEnd" class="col-2 col-form-label">Datetime end</label>
                <div class="col-5">
                  <input class="form-control" type="date" id="dateEnd" v-model="dateEnd">
                  <span class="help-block error text-danger" v-if="response_error.end_datetime !== undefined">
            {{ response_error.end_datetime[0] }}</span>
                </div>
                <div class="col-5">
                  <input class="form-control" type="time" id="timeEnd" v-model="timeEnd">
                </div>
              </div>


              <div class="form-group row">
                <label for="inputTimeZone" class="col-2 col-form-label">Timezone</label>
                <div class="col-10">
                  <select id="inputTimeZone" class="form-control" v-model="inputTimeZone">
                    <option v-for="tz in timezones" :key="tz" :value="tz">
                      {{ tz }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label for="availabilityFactor" class="col-2 col-form-label">Availability factor</label>
                <div class="col-10">
                  <input class="form-control" id="availabilityFactor" v-model="availabilityFactor">
                  <span class="help-block">The availability factor is a value between 0 and 1.0, where 1.0 means full capacity available and 0 denotes no capacity available. Note that 1.0 cannot be set. </span>
                  <span class="help-block error text-danger"
                        v-if="response_error.availability_factor !== undefined">
            {{ response_error.availability_factor[0] }}
          </span>
                </div>
              </div>

              <div class="form-group row">
                <label for="description" class="col-2 col-form-label">Description</label>
                <div class="col-10">
                  <input class="form-control" type="text" id="description" v-model="description">
                  <span class="help-block error text-danger" v-if="response_error.description !== undefined">
            {{ response_error.description[0] }}
          </span>

                </div>
              </div>

              <div class="col-md-12 alert alert-danger" role="alert" v-for="e in nonFieldErrors" :key="e">
                {{ e }}
              </div>

              <button type="submit" class="btn btn-primary" :disabled="!canSubmit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SelectPortfolio from "@/components/SelectPortfolio";
import SelectCluster from "@/components/SelectCluster";
import SelectAsset from "@/components/SelectAsset";
import moment from "moment-timezone";
import {timezones} from "@/utils/timezones";
import axios from "axios";
import _ from "underscore"

// see https://vsupalov.com/avoid-csrf-errors-axios-django/
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"


export default {
  name: 'AddMaintenanceEvent',
  props: {
    eventEdit: Object,
  },
  components: {SelectAsset, SelectCluster, SelectPortfolio},
  data: function () {
    var date_today = moment().add(1, "day").format('YYYY-MM-DD')
    return {
      selectedPortfolio: {slug: null},
      selectedCluster: {slug: null},
      selectedAsset: {slug: null},
      dateStart: date_today,
      timeStart: "",
      dateEnd: date_today,
      timeEnd: "",
      availabilityFactor: 1.0,
      inputTimeZone: "Europe/Amsterdam",
      timezones: timezones,
      description: "",
      response_error: {},
      initClusterSelect: false,
      initAssetSelect: false,
    }
  },

  methods: {
    submitForm: function (e) {
      e.preventDefault();

      this.response_error = {};

      if (this.eventEdit === null) {
        this.createNew()
      } else {
        this.submitEdit()
      }
    },

    createNew: function () {
      var url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/maintenance_event/`
      axios.post(url, this.event_obj).then(r => {
        this.$store.dispatch("maintenance/addEvent", r.data)
        this.$emit("maintenanceEventAdded", r.data)
      }).catch(error => {
        if (error.response.status === 500) {
          this.response_error = {"__all__": ["Server crashed, try again later."]}
        } else {
          this.response_error = error.response.data;
        }
      })
      /*
      try {
        var r = await axios.post(url, this.event_obj)
      } catch (error) {
        console.log(error)
        if (error.response.status === 500) {
          this.response_error = {"__all__": ["Server crashed, try again later."]}
        } else {
          this.response_error = error.response.data;
        }
        return
      }

      this.$store.dispatch("maintenance/addEvent", r.data)
      this.$emit("maintenanceEventAdded", r.data)
      */
    },
    submitEdit: async function () {
      let url = `/v1/portfolio/${this.selectedPortfolio.slug}/cluster/${this.selectedCluster.slug}/asset/${this.selectedAsset.slug}/maintenance_event/${this.eventEdit.id}/`
      try {
        var r = await axios.patch(url, this.event_obj);
      } catch (error) {
        if (error.response.status === 500) {
          this.response_error = {"__all__": ["Server crashed, try again later."]}
        } else {
          this.response_error = error.response.data;
        }
        return
      }

      this.$store.dispatch("maintenance/updateEvent", r.data)
      this.$emit("maintenanceEventEdited", r.data)
    },
    loadDataFromEditedObject: function () {
      var asset = this.eventEdit.asset_obj
      var cluster = _.find(
          this.$store.state.cluster.fullList, p => p.slug === asset.cluster
      )
      this.selectedPortfolio = _.find(
          this.$store.state.portfolio.fullList, p => p.slug === cluster.portfolio
      )
      this.selectedCluster = cluster
      this.selectedAsset = asset

      this.availabilityFactor = this.eventEdit.availability_factor
      var dtStartMoment =  moment(this.eventEdit.start_datetime).tz(this.selectedPortfolio.timezone)
      var dtEndMoment =  moment(this.eventEdit.end_datetime).tz(this.selectedPortfolio.timezone)

      this.dateStart = dtStartMoment.format('YYYY-MM-DD')
      this.timeStart = dtStartMoment.format('HH:mm')

      this.dateEnd = dtEndMoment.format('YYYY-MM-DD')
      this.timeEnd = dtEndMoment.format('HH:mm')

      this.description = this.eventEdit.description
    },

    cleanData: function () {
      // clean the form from edited object
      this.selectedPortfolio = {slug: null}
      this.selectedCluster = {slug: null}
      this.selectedAsset = {slug: null}

      this.availabilityFactor = 1.0
      this.description = ""
    }

  },

  computed: {
    canSubmit: function () {
      return (
          this.selectedAsset.slug != null &&
          this.selectedPortfolio.slug != null &&
          this.selectedCluster.slug != null
      );
    },

    nonFieldErrors: function () {
      // django puts general errors to __all__
      if (_.has(this.response_error, '__all__')) {
        return this.response_error['__all__']
      }
      if (_.has(this.response_error, 'non_field_errors')) {
        return this.response_error['non_field_errors']
      }
      return []
    },

    datetimeStartISO: function () {
      // combine date, time and timezone together
      return moment.tz(`${this.dateStart} ${this.timeStart}`, this.inputTimeZone).format()
    },
    datetimeEndISO: function () {
      return moment.tz(`${this.dateEnd} ${this.timeEnd}`, this.inputTimeZone).format()
    },

    event_obj: function () {
      return {
        "asset": this.selectedAsset.slug,
        "availability_factor": this.availabilityFactor,
        "start_datetime": this.datetimeStartISO,
        "end_datetime": this.datetimeEndISO,
        "description": this.description,
      }
    }
  },

  watch: {
    selectedPortfolio: function () {
      this.inputTimeZone = this.selectedPortfolio.timezone;
    },
    eventEdit: function () {
      /* this form servers to create and edit events, when user clicks on "edit event"
      *  this.eventEdit is changed and we load values from that event
      */
      if (this.eventEdit) {
        this.loadDataFromEditedObject();
      } else {
        this.cleanData()
      }
    }
  }

}
</script>

<style>
#inputDateHistory {
  margin-top: 12px;
}
</style>