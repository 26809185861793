<template>
  <table class="table w-100">
    <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col" v-for="column in imbaStats.columns" :key="column">{{ column }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(dataRow, index) in this.imbaStats.rows" :key="index">
      <td v-for="(dp, index) in dataRow" :key="index">{{ dp }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'ForecastCostTable',
  props: {
    imbaStats: Object,
  }
}
</script>