<template>
  <v-grid
      v-if="hasLoadedEvents"
      theme="material"
      resize="true"
      readonly="true"
      :autoSizeColumn="autoSizeMode"
      :filter="filterConfig"
      :source="eventList"
      :columns="columns"
      :columnTypes="plugins"
  ></v-grid>
  <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import VGrid, { VGridVueTemplate } from "@revolist/vue3-datagrid";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import MaintenanceEventActions from "@/components/MaintenanceEventActions";
import NumberColumnType from '@revolist/revogrid-column-numeral';
import moment from "moment-timezone";
import {mapGetters} from "vuex";

export default {
  name: "MaintenanceEventTable",
  components: { VGrid, Loading },
  props: ['eventList'],
  computed: {
    ...mapGetters({
      hasLoadedEvents: 'maintenance/hasLoadedEvents',
    })
  },
  methods: {
    stringFilter(cellValue, extraValue) {
      if (!cellValue) {
        return false;
      }
      if (moment.isMoment(cellValue)) {
        cellValue = cellValue.format('ddd MMM DD YYYY hh:mm:ss')
      }
      if (typeof cellValue !== 'string') {
        cellValue = JSON.stringify(cellValue);
      }
      return cellValue.includes(extraValue);
    },
  },
  data: function () {
    return {
      filterConfig: {
        include: ['contains'],
        customFilters: {
          'contains': {
            columnFilterType: 'stringFilter',
            name: 'Contains',
            func: this.stringFilter
          }
        },
      },
      autoSizeMode: false,
      plugins: {
        'numeric': new NumberColumnType('0.0'),
      },
      columns: [
        {
          name: 'Cluster',
          prop: 'cluster',
          size: 120,
          autoSize: true,
          sortable: true,
          filter: 'myFilterType'
        },
        {
          name: 'Asset',
          prop: 'asset',
          sortable: true,
          filter: 'myFilterType'
        },

        {
          name: 'Asset Name',
          prop: 'asset_name',
          size: 160,
          autoSize: true,
          filter: false,
        },
        {
          name: 'Start',
          prop: 'start',
          size: 180,
          autoSize: true,
          sortable: true,
          filter: 'myFilterType'
        },
        {
          name: 'End',
          prop: 'end',
          size: 180,
          autoSize: true,
          filter: false,
        },
        {
          name: 'Availability Factor',
          prop: 'availability_factor',
          size: 120,
          columnType: 'numeric',
          autoSize: true,
          filter: false,
        },
        {
          name: 'Description',
          prop: 'description',
          size: 160,
          autoSize: true,
          filter: false,
        },
        {
          name: '',
          prop: 'action_list',
          size: 110,
          cellTemplate: VGridVueTemplate(MaintenanceEventActions),
          filter: false,
        }
      ],
      isLoading: true,
    }
  },
  watch: {
    hasLoadedEvents() {
      this.isLoading = !this.hasLoadedEvents;
    }
  },
  mounted() {
    this.stringFilter = this.stringFilter.extra = 'input';
  },
}
</script>

<style scoped>
svg {
  display: block;
  margin: 140px auto;
  animation: spin 2s infinite linear;
}
</style>
<style>
.filter-img {
  width: 16px !important;
}
revogr-header .rgHeaderCell i.asc:after, revogr-header .rgHeaderCell i.desc:after {
  font-size: 16px !important;
}
</style>