export const columnNames = {
    "load": { name: "Actual Load", suffix: " kWh" },
    "pred": { name: "Predicted Load", suffix: " kWh" },
    "imba_h": { name: "Imbalance per hour", suffix: " kWh" },
    "imba_h_abs": { name: "Absolute imbalance per hour", suffix: " kWh" },
    "imba_irr": { name: "Irreducible imbalance", suffix: " kWh" },
    "imba_pte": { name: "Imbalance per pte", suffix: " kWh" },
    "imba_pte_abs": { name: "Absolute imbalance per pte", suffix:" kWh" },
    "imba_cost_h": { name: "Cost per hour", suffix: " €/MWh" },
    "imba_cost_irr": { name: "Irreducible cost", suffix: " €/MWh" },
    "imba_cost_pte": { name: "Cost per pte", suffix: " €/MWh" },
    "forecast_age": { name: "Age of forecast", suffix: " hours" },
    "scaling": { name: "Cluster scaling information", suffix: "" },
}

export const metaColumnNames = {
    "LOAD_SOLAR_COMPONENT": { name: "Solar influence on load forecast", suffix: "" },
    "LOAD_WIND_COMPONENT": { name: "Wind influence on load forecast", suffix: "" },
    "LOAD_TEMPERATURE_COMPONENT": { name: "Temperature influence on load forecast", suffix: "" },
    "COUNT_ASSET": { name: "Number of assets in (aggregated) forecast", suffix: "" },
    "PROFILED_YEAR_USAGE": { name: "Yearly usage for asset", suffix: "" },
    "LOAD_WIND_SHUTDOWN_COMPONENT": { name: "Scaling factor for turbine shutdown due to high wind speed", suffix: "" },
    "WIND_ICING_PROBABILITY": { name: "Wind icing probability", suffix: "" },
    "WIND_ICING_ENERGY_LOSS_KWH": { name: "Wind icing energy loss (kWh)", suffix: "" },
}


export const loadColumns = ['pred', 'load']
export const imbaColumns = [
    "imba_h",
    "imba_h_abs",
    "imba_irr",
    "imba_pte",
    "imba_pte_abs",
]

export const costColumns = [
    "imba_cost_h",
    "imba_cost_irr",
    "imba_cost_pte",
]
